import React from "react"
import { Box } from "@chakra-ui/react"
import Video from "../video"

const styles = {
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: { base: "100%", md: "100%", lg: "655px" },
    width: "100%",
    px: { base: "24px", md: "24px", lg: "10%" },
    marginTop: { base: "40px", md: "40px", lg: "0px" },
  },
  video: {
    maxWidth: "883px",
    height: { base: "auto", md: "auto", lg: "494.16px" },
    width: "100%",
  },
}

const OssVideo = () => (
  <Box sx={styles.box}>
    <Box sx={styles.video}>
      <Video video="https://player.vimeo.com/video/796300948?h=ce0f31d06b" />
    </Box>
  </Box>
)

export default OssVideo