import React from "react"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import OssVideo from "./oss-health-video"

const styles = {
  root: {
    width: "100%",
    bg: "#F4F4F4",
    mt: { base: "32px", md: "32px", lg: "64px" },
    px: "24px",
  },

  "heading-box": {
    margin: "0 auto",
    mb: "32px",
    width: { base: "100%", md: "100%", lg: "100%" },
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    mt: { base: "24px", md: "24px", lg: "32px" },
  },

  "inner-box": {
    flexDirection: {
      base: "column-reverse",
      md: "column-reverse",
      lg: "row",
    },
    width: { base: "100%", md: "100%", lg: "1112px" },
    margin: "0 auto",
  },

  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
}

const SectionOSSHealth = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>
        How one Hospital is Increasing Reimbursement
        </Heading>
      </Flex>

      <Flex sx={styles["inner-box"]}>
            <Text sx={styles.text}>
              Even with advanced CDI, costly coding and billing errors are often missed. 
              Watch this video to learn how OSS Health is capturing additional revenue 
              by identifying discrepancies between the coding of surgical cases and the supplies used.
            </Text>
      </Flex>
      <OssVideo/>
    </Box>
  )
}

export default SectionOSSHealth
