import React from "react"
import Layout from "../components/layout"
import SectionHero from "../components/coding-optimization/section-hero"
import SectionMiscodedCases from "../components/coding-optimization/section-miscoded-cases"
import SectionVeryDifferent from "../components/coding-optimization/section-very-different"
import SectionOSSHealth from "../components/coding-optimization/section-oss-health"
import SectionCaseStudy from "../components/coding-optimization/section-case-study"
import SectionMoreAccurate from "../components/coding-optimization/section-more-accurate"
import SectionCloserLook from "../components/coding-optimization/section-closer-look"

class SurgicalCodingOptimizationIndex extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        title={"Surgical Coding Optimization| Avant-Garde Health"}
      >
        <SectionHero />
        <SectionMiscodedCases />
        <SectionVeryDifferent />
        <SectionOSSHealth />
        <SectionCaseStudy />
        <SectionMoreAccurate />
        <SectionCloserLook />
      </Layout>
    )
  }
}

export default SurgicalCodingOptimizationIndex
