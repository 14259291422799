import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import HeroLeft from "../../components/hero-left"

const styles = {
  root: {
    height: "100%",
    bg: "#F4F4F4",
    px: "24px",
  },

  "heading-box": {
    margin: "0 auto",
    mb: "32px",
    width: { base: "65%", md: "65%", lg: "100%" },
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    mt: { base: "24px", md: "24px", lg: "32px" },
  },
  "title-box": {
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "32px" },
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
  },

  "content-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
  },

  hero: {
    "inner-box": {
      width: { base: "100%", md: "100%", lg: "1112px" },
      justifyContent: {
        base: "flex-start",
        md: "flex-start",
        lg: "space-between",
      },
      alignItems: { base: "center", md: "center", lg: "flex-start" },
    },
  },

  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  whitespace: {
    pb: "24px",
  },

  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}
const navigateToLearnMoreAboutCodingOptimization = () =>
  navigate("/learn-more-about-surgical-coding-optimization")
const SectionMoreAccurate = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>More Accurate and Easier Too</Heading>
      </Flex>
      <HeroLeft
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/coding-optimization/co-more-accurate.png"
            alt="Illustration people inside of a puzzle piece background"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box"]}>
          <Text sx={styles.text}>
            If you’ve implemented medical coding software, the time-to-benefit
            was probably measured in months.
            <Text sx={styles.whitespace}></Text>
            With Avant-garde, you’ll see new opportunities for revenue capture
            in days.
            <Text sx={styles.whitespace}></Text>
            All that’s needed is a simple data extract from your EMR system.
            We’ve set this up many times with all the major EMRs – It usually
            takes only a day or two working with your IT team. Once set up, the
            platform starts highlighting new revenue opportunities.
          </Text>
        </Flex>
      </HeroLeft>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "147px",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigateToLearnMoreAboutCodingOptimization}
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionMoreAccurate
