import React from "react"
import { navigate } from "gatsby"
import { Box, Button, chakra, Flex, Heading, Text } from "@chakra-ui/react"

const styles = {
  root: {
    width: "100%",
    px: "24px",
  },

  "heading-box": {
    margin: "0 auto",
    mb: "32px",
    width: { base: "100%", md: "100%", lg: "100%" },
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    mt: { base: "24px", md: "24px", lg: "32px" },
  },

  "inner-box": {
    flexDirection: {
      base: "column-reverse",
      md: "column-reverse",
      lg: "row",
    },
    width: { base: "100%", md: "100%", lg: "1112px" },
    margin: "0 auto",
  },
  "title-box": {
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "32px" },
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
  },

  "content-box": {
    width: { base: "100%", md: "100%", lg: "50%" },
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },

  card: {
    flexDirection: "column",
    border: "1px solid #DCE0E5",
    borderRadius: "8px",
    width: { base: "327px", md: "327px", lg: "543px" },
    height: { base: "408px", md: "408px", lg: "280px" },
    padding: "32px",
    mb: { base: "24px", md: "24px", lg: "0px" },
  },

  "case-detail": {
    justifyContent: "space-between",
    mb: "8px",
  },

  "text-box": {
    width: "429px",
    mb: { base: "24px", md: "24px", lg: "0px" },
  },

  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  whitespace: {
    pb: "24px",
  },

  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}
const navigateToLearnMoreAboutCodingOptimization = () => navigate("/learn-more-about-surgical-coding-optimization")
const SectionCaseStudy = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>
          Medical Coding Optimization: A Case Study
        </Heading>
      </Flex>

      <Flex sx={styles["inner-box"]}>
        <Flex sx={styles["content-box"]}>
          <Flex sx={styles["text-box"]}>
            <Text sx={styles.text}>
              A regional hospital bills for approximately 3,500 Endoscopy
              procedures each year. The code review process and systems identify
              minor coding discrepancies in approximately 5% of the cases.
              <Text sx={styles.whitespace}></Text>
              The Avant-garde coding optimization platform then examines these
              same cases and flags significant, undetected coding errors. Based
              on the supplies used in each case, 2% of the procedures billed as
              EGD procedures should have been billed as ERCP cases at an average
              increase of $3,787 per case. The net increase for the hospital
              based on this one procedure is more than $250k per year.
            </Text>
          </Flex>
        </Flex>
        <Flex sx={styles["content-box"]}>
          <Box sx={styles.card}>
            <Flex sx={styles["title-box"]}>
              <Heading sx={styles.title}>Case Study</Heading>
            </Flex>
            <Flex sx={styles["case-detail"]}>
              <Text>Total Annual Endoscopy Procedures:</Text>
              <chakra.span>3,546</chakra.span>
            </Flex>

            <Flex sx={styles["case-detail"]}>
              <Text>ERCP Cases miscoded as EGD:</Text>
              <chakra.span>2.03%</chakra.span>
            </Flex>

            <Flex sx={styles["case-detail"]}>
              <Text>Total ERCP Cases miscoded as EGD: </Text>
              <chakra.span>72</chakra.span>
            </Flex>
            <Flex sx={styles["case-detail"]}>
              <Text>Reimbursement Increase per Case: </Text>
              <chakra.span>$3,787</chakra.span>
            </Flex>
            <Flex sx={styles["case-detail"]}>
              <Text>Annual Potential Revenue Increase:</Text>
              <chakra.span>$272,664</chakra.span>
            </Flex>
          </Box>
        </Flex>
      </Flex>

      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "147px",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigateToLearnMoreAboutCodingOptimization}
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionCaseStudy
