import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import HeroLeft from "../../components/hero-left"
import HeroRight from "../../components/hero-right"

const styles = {
  root: {
    height: "100%",
    px: "24px",
  },

  "heading-box": {
    margin: "0 auto",
    mt: { base: "24px", md: "24px", lg: "32px" },
    mb: "32px",
    width: { base: "65%", md: "65%", lg: "100%" },
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
  },

  hero: {
    "inner-box": {
      width: { base: "100%", md: "100%", lg: "1112px" },
      justifyContent: {
        base: "flex-start",
        md: "flex-start",
        lg: "space-between",
      },
      alignItems: { base: "center", md: "center", lg: "flex-start" },
      flexDirection: { base: "column", md: "column", lg: "row" },
    },
  },

  "title-box": {
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "32px" },
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  "content-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
    ml: { base: "0px", md: "0px", lg: "140px" },
  },

  "content-box-border": {
    mt: { base: "24px", md: "24px", lg: "0px" },
    maxWidth: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
    border: { base: "1px solid #DCE0E5", md: "1px solid #DCE0E5", lg: "none" },
    borderRadius: { base: "8px", md: "8px", lg: "none" },
    padding: { base: "35px", md: "35px", lg: "0px" },
  },

  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  whitespace: {
    pb: "24px",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToLearnMoreAboutCodingOptimization = () =>
  navigate("/learn-more-about-surgical-coding-optimization")
const SectionVeryDifferent = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>This is Different, Very Different</Heading>
      </Flex>
      <HeroLeft
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/coding-optimization/co-very-different-a.png"
            alt="Illustration of a doctor sitting at a desk taking notes from a laptop"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box"]}>
          <Flex sx={styles["title-box"]}>
            <Heading sx={styles.title}>Garbage in, garbage out</Heading>
          </Flex>
          <Text sx={styles.text}>
            Your billing reviews, automated or manual, are dependent on the opp
            notes. Long, confusing and often incomplete Op notes. Neither humans
            nor AI-enabled software can fix what’s broken from the start.
          </Text>
          <Text sx={styles.whitespace}></Text>
          <Flex sx={styles["title-box"]}>
            <Heading sx={styles.title}>
              Avant-garde eliminates the weakest link
            </Heading>
          </Flex>
          <Text sx={styles.text}>
            The Avant-garde coding optimization platform works completely
            independent of surgical opp notes. This unique platform identifies
            discrepancies between the supplies used in surgical and procedural
            cases and the billing of each case.
          </Text>
        </Flex>
      </HeroLeft>

      <HeroRight
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/coding-optimization/co-very-different-b.png"
            alt="Illustration of a person holding an oversized lightbulb and pencil in front of a stack of books "
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box-border"]}>
          <Flex sx={styles["title-box"]}>
            <Heading sx={styles.title}>A Common Example</Heading>
          </Flex>
          <Text sx={styles.text}>
            A procedure coded as a Single-Level Posterior Lumbar Fusion was
            reviewed with AI-enabled medical coding software as well as the
            hospital’s internal code review team and a 3rd-Party review service.
            No errors were found.
            <Text sx={styles.whitespace}></Text>
            The case was run through the Avant-garde coding optimization
            platform. Based on the supplies used, Avant-garde correctly
            identified the procedure as a Combined Anterior/Posterior Spinal
            Fusion which when rebilled with the correct code, increases the
            payment to the hospital by $8,000.
          </Text>
        </Flex>
      </HeroRight>

      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          pb: "32px",
          width: "147px",
          height: "56px",
          margin: "0 auto",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigateToLearnMoreAboutCodingOptimization}
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionVeryDifferent
